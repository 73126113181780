import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ErrorPage from "./pages/error/ErrorPage";
import Landing from "./pages/landing/Landing";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleToastAction,
  toggleModalAction,
  toggleLoadingBlanket,
  getMobileMoneyWalletsAction,
  getUserBankWalletsAction,
  getAllDealsAction,
  listUsersPaymentElectionsAction,
  userDashboardStatsAction,
  getAllDealReportsAction,
  getAllDistributionsAction,
  toggleImagePreviewAction,
  getUserInvitationsAction,
  fetchExchangeRatesAction,
  updateUserInfoAction,
} from "./store/actions/actions";

// PAGE COMPONENTS
import LoadingPage from "./shared/components/loading/LoadingPage";
import DealResponsePage from "./pages/DealResponsePage";
import UserDashboard from "./pages/user dashboard/UserDashboard";
import DealDetailWrapper from "./pages/deals/DealDetailWrapper";
import WalletsWrapper from "./pages/user wallets/WalletsWrapper";
import UserPageWrapper from "./components/UserPageWrapper/UserPageWrapper";
import DistributionsList from "./pages/distributions/distributions-list/DistributionsList";
import FeedsList from "./pages/feeds/feeds-list/FeedsList";
import DistributionsDetail from "./pages/distributions/distribution-details/DistributionsDetail";
import InvitationDetailPage from "./pages/deals/my invitations/InvitationDetailPage";
import TermAndConditions from "./pages/terms and conditions/TermAndConditions";
import UserSettingsWrapper from "./pages/user settings/tab items/UserSettingsWrapper";
import EditSettingsWrapper from "./pages/user settings/edit-settings/EditSettingsWrapper";
import EnterpriseDetails from "./pages/enterprise detail/EnterpriseDetails";
import AssetDetail from "./pages/deals/deal details/asset detail/AssetDetail";
import FeedDetail from "./pages/feeds/feed-details/FeedDetail";
import MyDealDetailWrapper from "./pages/deals/deal details/MyDealDetailWrapper";
import CountryAndCurrencySelection from "./pages/user settings/country-and-currency/CountryAndCurrencySelection";
import Success from "./pages/Auth/Success";


//MISC COMPONENTS
import ImagePreview from "./shared/components/ImagePreview/ImagePreview";
import URLS from "./shared/js/urls";
import Overlay from "./shared/components/overlay/Overlay";
import { getMyCurrencyInfo } from "./shared/js/utils";
import { version } from "../package.json";
import ApiCallHandler from "./shared/js/ApiCallHandler";

import { Spinner, Toast, Modal} from "@kehillahglobal/ui";
import "@kehillahglobal/ui/dist/index.css";

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: false,
    }
  }
  
  async componentDidMount() {
    this.whoAmI(true);
  }

  makeAPICalls = () => {
    this.props.getMobileMoneyWallets();
    this.props.getBankWallets();
    this.props.getAllDeals();
    this.props.getUsersPaymentElections();
    this.props.getUserInvitations();
    this.props.getDashboardStats();
    this.props.getDealReports();
    this.props.getAllDistributions();
  };

  whoAmI = async (withApiCalls) => {
    let res = await ApiCallHandler.send(URLS.WHO_AM_I, "POST", {});
    if (res?.data) {
      this.setState({ authenticated: true });
      this.props.setUserData(res.data);
        this.makeAPICalls();
      if (window.location.pathname === "/") {
        window.location.pathname = `/client/${res?.data?.id}/dashboard`;
      }
    } 
    else {
      if (window.location.pathname !== "/") {
        if (window.location.pathname.indexOf("/success/") < 0) {
          window.location.pathname = "/";
        }
      }
    }
  };

  render() {
    const { blanketProps } = this.props;
    if (blanketProps.show)
      return (
        <LoadingPage
          {...blanketProps.props}
          close={this.props.toggleLoadingBlanket}
        />
      );

    return (
      <>
        <NotificationManager
          close={this.props.toggleToast}
          {...this.props.toastProps}
        />
        <ModalManager
          close={this.props.toggleModal}
          {...this.props.modalProps}
        />
        <ImagePreviewManager {...this.props.imagePreviewProps} />
        <OverlayManager {...this.props.overlayProps} />
        <Router>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route
              exact
              path="/client/:userID/deal/response/action/:invitationId"
              component={DealResponsePage}
            />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermAndConditions}
            />
            <Route
              exact
              path="/country-and-currency-selection"
              component={CountryAndCurrencySelection}
            />
            <Route exact path="/success" component={Success} />
            <AuthenticatedRoutes
              {...this.props}
              authenticated={this.state.authenticated}
            />
            {/* ------------------- THIS SHOULD BE THE LAST ROUTE AT ALL TIMES --------------_ */}
            <Route path="*" component={ErrorPage} />
          </Switch>
        </Router>
        <div style={{ position: "fixed", bottom: 5, right: 20 }}>
          <p style={{ color: "grey", fontSize: "0.9rem" }}>v{version}</p>
        </div>
      </>
    );
  }
}
const AuthenticatedRoutes = (props) => {
  const params = window.location.href.toString().split("entry=")[1];
  const user = props.userData;

  if (!user?.id) {
    return <LoadingPage text={`Loading user data`} />;
  } else if (!params) {
    if (!user?.id) {
      window.location.href = '/';
    } else if (!user?.country && !user?.currency) {
      return <Redirect to="/country-and-currency-selection" />;
    } else if (!user?.has_accepted_tcs) {
      return <Redirect to="/terms-and-conditions" />;
    }
  }
  if (props.authenticated) {
    props.getExchangeRates(getMyCurrencyInfo(user?.currency)?.code);
    return (
      <UserPageWrapper>
        <Switch>
          <Route
            exact
            path="/client/:userID/dashboard"
            component={UserDashboard}
          />
          <Route
            exact
            path="/client/:userID/deals/feeds"
            component={FeedsList}
          />
          <Route
            exact
            path="/client/:userID/deals/distributions"
            component={DistributionsList}
          />
          <Route
            exact
            path="/client/:userID/deals"
            component={DealDetailWrapper}
          />
          <Route
            exact
            path="/client/:userID/deals/:dealID/partnerships/:partnershipID"
            component={MyDealDetailWrapper}
          />
          <Route
            exact
            path="/client/:userID/deals/:dealID/assets/:assetID"
            component={AssetDetail}
          />
          <Route
            exact
            path="/client/:userID/deals/invitation/:id"
            component={InvitationDetailPage}
          />
          <Route
            exact
            path="/client/:userID/wallets"
            component={WalletsWrapper}
          />
          <Route
            exact
            path="/client/:userID/settings"
            component={UserSettingsWrapper}
          />
          <Route
            exact
            path="/client/:userID/settings/update"
            component={EditSettingsWrapper}
          />

          <Route
            exact
            path="/client/:userID/deals/feeds/:id"
            component={FeedDetail}
          />

          <Route
            exact
            path="/client/:userID/deals/distributions/:id"
            component={DistributionsDetail}
          />
          <Route
            exact
            path="/client/:userID/partnership/enterprise/:enterpriseID"
            component={EnterpriseDetails}
          />
          <Route component={ErrorPage} />
        </Switch>
      </UserPageWrapper>
    );
  } else {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner variation={"TwoHalfCirclesType"} />
      </div>
    );
  }
};

const ModalManager = ({ show, props, close }) => {
  if (show)
    return (
      <Modal close={() => close()} {...props}>
        {props?.children}
      </Modal>
    );
  return <></>;
};
const NotificationManager = (props) => {
  if (props.show) return <Toast close={() => props.close()} {...props} />;
  return <></>;
};

const ImagePreviewManager = (props) => {
  if (props.show) return <ImagePreview {...props} />;
  return <></>;
};
const OverlayManager = (props) => {
  if (props.show) return <Overlay {...props} />;
  return <></>;
};
const mapStateToProps = (state) => {
  return {
    toastProps: state.toastProps,
    modalProps: state.modalProps,
    blanketProps: state.blanketProps,
    imagePreviewProps: state.imagePreviewProps,
    overlayProps: state.overlayProps,
    userData: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      toggleImagePreview: toggleImagePreviewAction,
      toggleLoadingBlanket,
      getMobileMoneyWallets: getMobileMoneyWalletsAction,
      getBankWallets: getUserBankWalletsAction,
      getAllDeals: getAllDealsAction,
      getUsersPaymentElections: listUsersPaymentElectionsAction,
      getUserInvitations: getUserInvitationsAction,
      getDashboardStats: userDashboardStatsAction,
      getDealReports: getAllDealReportsAction,
      getAllDistributions: getAllDistributionsAction,
      setUserData: updateUserInfoAction,
      getExchangeRates: fetchExchangeRatesAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
